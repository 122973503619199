/* globals window, Motio */
/*! motio 2.2.2 - 7th Aug 2014 | https://github.com/darsain/motio */
(function(h, C) {
  function l(a, D) {
    function k(c) {
      d.reversed = c;
      s || ((b.isPaused = !1), x("play"), (s = u(l)));
    }
    function l() {
      s = u(l);
      const c = E();
      (e.fps < 60 && d.lastFrame && d.lastFrame + 1e3 / e.fps + 1 > c) ||
        ((d.lastFrame = c),
        v
          ? ((g.x += e.speedX / e.fps),
            (g.y += e.speedY / e.fps),
            e.bgWidth && Math.abs(g.x) > e.bgWidth && (g.x %= e.bgWidth),
            e.bgHeight && Math.abs(g.y) > e.bgHeight && (g.y %= e.bgHeight))
          : (d.finite
              ? (m = d.immediate ? d.to : m + (m > d.to ? -1 : 1))
              : d.reversed
              ? --m <= 0 && (m = q.length - 1)
              : ++m >= q.length && (m = 0),
            (b.frame = m)),
        (y = v ? `${Math.round(g.x)}px ${Math.round(g.y)}px` : q[m]),
        y !== A && (a.style.backgroundPosition = A = y),
        x("frame"),
        d.finite &&
          d.to === m &&
          (b.pause(), p(d.callback) === "function" && d.callback.call(b)));
    }
    function B(c, b) {
      r = 0;
      for (w = n[c].length; r < w; r++) if (n[c][r] === b) return r;
      return -1;
    }
    function x(c, a) {
      if (n[c]) for (r = 0, w = n[c].length; r < w; r++) n[c][r].call(b, c, a);
    }
    function z(c) {
      return h.getComputedStyle
        ? h.getComputedStyle(a, null)[c]
        : a.currentStyle[c];
    }
    var e = F(D);
    var b = this;
    var v = !e.frames;
    var q = [];
    var n = {};
    var d = {};
    var m = 0;
    let g;
    let y;
    let A;
    let s;
    let r;
    let w;
    b.element = a;
    b.width = e.width || a.clientWidth;
    b.height = e.height || a.clientHeight;
    b.options = e;
    b.isPaused = !0;
    b.pause = function() {
      t(s);
      s = 0;
      d.lastFrame = 0;
      b.isPaused || ((b.isPaused = !0), x("pause"));
      return b;
    };
    b.play = function(c) {
      d.finite = !1;
      d.callback = C;
      d.immediate = !1;
      k(c);
      return b;
    };
    b.toggle = function() {
      b[s ? "pause" : "play"]();
      return b;
    };
    b.toStart = function(c, a) {
      return b.to(0, c, a);
    };
    b.toEnd = function(c, a) {
      return b.to(q.length - 1, c, a);
    };
    b.to = function(c, a, f) {
      if (v || isNaN(parseFloat(c)) || !isFinite(c) || c < 0 || c >= q.length) {
        return b;
      }
      p(a) === "function" && ((f = a), (a = !1));
      if (c === m) {
        if (c === 0) m = q.length;
        else if (c === q.length - 1) m = -1;
        else return p(f) === "function" && f.call(b), b.pause(), b;
      }
      d.finite = !0;
      d.to = c;
      d.immediate = !!a;
      d.callback = f;
      k();
      return b;
    };
    b.set = function(c, a) {
      e[c] = a;
      return b;
    };
    b.on = function(c, a) {
      if (p(c) === "object") {
        for (var f in c) {
          if (c.hasOwnProperty(f)) b.on(f, c[f]);
        }
      } else if (p(a) === "function") {
        f = c.split(" ");
        for (var d = 0, e = f.length; d < e; d++) {
          (n[f[d]] = n[f[d]] || []), B(f[d], a) === -1 && n[f[d]].push(a);
        }
      } else if (p(a) === "array") {
        for (f = 0, d = a.length; f < d; f++) b.on(c, a[f]);
      }
      return b;
    };
    b.off = function(a, d) {
      if (d instanceof Array) {
        for (var f = 0, e = d.length; f < e; f++) b.off(a, d[f]);
      } else {
        for (var f = a.split(" "), e = 0, g = f.length; e < g; e++) {
          if (((n[f[e]] = n[f[e]] || []), p(d) === "undefined")) {
            n[f[e]].length = 0;
          } else {
            const h = B(f[e], d);
            h !== -1 && n[f[e]].splice(h, 1);
          }
        }
      }
      return b;
    };
    b.destroy = function() {
      b.pause();
      a.style.backgroundPosition = "";
      return b;
    };
    (function() {
      let a = (
        z("backgroundPosition") ||
        `${z("backgroundPositionX")} ${z("backgroundPositionY")}`
      )
        .replace(/left|top/gi, 0)
        .split(" ");
      g = { x: 0 | parseInt(a[0], 10), y: 0 | parseInt(a[1], 10) };
      if (v) b.pos = g;
      else {
        for (a = q.length = 0; a < e.frames; a++) {
          e.vertical ? (g.y = a * -b.height) : (g.x = a * -b.width),
            q.push(`${g.x}px ${g.y}px`);
        }
        b.frames = q.length;
        b.frame = 0;
      }
    })();
  }
  function p(a) {
    return a == null
      ? String(a)
      : typeof a === "object" || typeof a === "function"
      ? (a instanceof h.NodeList && "nodelist") ||
        (a instanceof h.HTMLCollection && "htmlcollection") ||
        Object.prototype.toString
          .call(a)
          .match(/\s([a-z]+)/i)[1]
          .toLowerCase()
      : typeof a;
  }
  function F(a) {
    const h = {};
    a = p(a) === "object" ? a : {};
    for (const k in l.defaults) {
      h[k] = (a.hasOwnProperty(k) ? a : l.defaults)[k];
    }
    return h;
  }
  var t = h.cancelAnimationFrame || h.cancelRequestAnimationFrame;
  var u = h.requestAnimationFrame;
  (function() {
    for (
      var a = ["moz", "webkit", "o"], p = 0, k = 0, l = a.length;
      k < l && !t;
      ++k
    ) {
      u =
        (t =
          h[`${a[k]}CancelAnimationFrame`] ||
          h[`${a[k]}CancelRequestAnimationFrame`]) &&
        h[`${a[k]}RequestAnimationFrame`];
    }
    t ||
      ((u = function(a) {
        const k = +new Date();
        const l = Math.max(0, 16 - (k - p));
        p = k + l;
        return h.setTimeout(() => {
          a(k + l);
        }, l);
      }),
      (t = function(a) {
        clearTimeout(a);
      }));
  })();
  var E = (function() {
    const a = h.performance;
    return a && a.now
      ? a.now.bind(a)
      : function() {
          return +new Date();
        };
  })();
  h.Motio = l;
  l.defaults = {
    fps: 15,
    frames: 0,
    vertical: 0,
    width: 0,
    height: 0,
    speedX: 0,
    speedY: 0,
    bgWidth: 0,
    bgHeight: 0
  };
})(window);

function createAnimation(holder, targetID, frames, interval, width) {
  const holderModule = holder;
  if (!holderModule) {
    return false;
  }
  const animationEl = document.createElement("div");
  animationEl.id = targetID;
  holderModule.appendChild(animationEl);
  holderModule.classList.add("animating");
  const motio = new Motio(animationEl, {
    fps: 24,
    frames,
    width
  });
  if (interval) {
    animationEl.addEventListener("mouseover", () => {
      motio.toEnd();
    });
    return setInterval(motio.toEnd, interval);
  }
  motio.play();
  return true;
}

if (Motio) {
  window.addEventListener(
    "load",
    () =>
      setTimeout(() => {
        const coffee = createAnimation(
          document.querySelector("#testimonials"),
          "coffee-animation",
          49
        );
        const phone = createAnimation(
          document.querySelector(".customer-cta-1 .cta .slim div:last-child"),
          "phone-animation",
          81,
          25000
        );
        const purse = createAnimation(
          document.querySelector(".how_it_works #benefits"),
          "purse-animation",
          67,
          12000
        );
      }, 3000),
    false
  );
}

// This is used so the nav bar arrow animations don't happen on page load
setTimeout(() => {
  document.body.classList.remove("preload");
}, 500);
