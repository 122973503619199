const theme = {
  desktopWidth: "1060px",
  colors: {
    beige: "#F8F6EF",
    basicBlue: "#3f83ee",
    blue: "#1e4464",
    blogGrey: "#7c8992",
    darkBlue: "#2E404F",
    darkBlueLow: "#24333F",
    darkLinen: "#dfd9ce",
    uiGrey: "#D4E4E7",
    formText: "#4A4A4A",
    lightBlue: "#56A6D1",
    lightBlue2: "#a6dde0",
    lightBlue3: "#6EABF2",
    lightBlueHover: "#5EB8E8",
    lightestBlue: "#EEF6FA",
    lightCoral: "#f08992",
    lightCyan: "#d3f3fe",
    lighterCyan: "#ddf5f4",
    lightGreen: "#98d984",
    lighterGreen: "#dfffd5",
    lightRed: "#DA717C",
    lightestGrey: "#FCFCFC",
    lightestRed: "#FBEFEF",
    lightGrey: "#F7F7F7",
    lightBiege: "#f5f3ee",
    grey: "#DADADA",
    lavendar: "#f1dfff",
    linen: "#f7f0e4",
    midCyan: "#ABE1F2",
    midGrey: "#979797",
    mistyrose: "#ffe1e4",
    none: "none",
    orange: "#DF6150",
    peachpuff: "#ffd7b5",
    plum: "#c38deb",
    red: "#D35663",
    sandybrown: "#f3a15f",
    shadowBeige: "rgba(245,243,238, 0.6)",
    shadowBlue: "rgba(86, 166, 209, 0.6)",
    shadowGrey: "rgba(0, 0, 0, 0.15)",
    shadowRed: "rgba(216, 102, 99, 0.60)",
    skyBlue: "#8ac3dc",
    statusBlue: "#4187D9",
    statusGreen: "#44B28B",
    statusGrey: "#4A4A4A",
    statusOrange: "#F7892E",
    statusPurple: "#551a8b",
    statusRed: "#ED205E",
    viPink: "#F41C5E",
    warningRed: "#D35663",
    whiteSmoke: "#f5f5f5",
    white: "#FFFFFF"
  },
  typography: {
    fontStack: "'Source Sans Pro', sans-serif",
    defaultFontSize: "18px",
    defaultLineHeight: "26px"
  },
  screen: {
    mobile: 500,
    tablet: 768,
    desktop: 1024
  },
  zIndexes: {
    menu: "50",
    modal: "999"
  }
};

export default theme;
