import WebFont from "webfontloader";

WebFont.load({
  google: {
    families: [
      "Merriweather:400,400i,600,700i",
      "Source Sans Pro:400,500,600,700",
      "Open Sans:400,500,600,700"
    ]
  }
});
