import Glide from "@glidejs/glide";
import theme from "theme";

const { desktop } = theme.screen;

const carousel = document.querySelector(".glide") || null;
const noPeek = document.querySelector(".no-peek") || null;
const continuous = document.querySelector(".continuous") || null;
if (carousel) {
  const extraOptions = continuous
    ? {
        perView: 4,
        type: "carousel",
        gap: 0,
        peek: 100,
        breakpoints: {
          [desktop]: {
            gap: 0,
            peek: 100
          }
        }
      }
    : {};

  const options = {
    peek: noPeek ? 0 : 150,
    gap: noPeek ? 0 : 30,
    dragThreshold: 40,
    swipeThreshold: 40,
    breakpoints: {
      [desktop]: {
        gap: 0,
        peek: 0
      }
    },
    ...extraOptions
  };
  const glide = new Glide(".glide", options);

  carousel.addEventListener("click", () => {
    glide.go(">");
  });

  glide.mount();
}
