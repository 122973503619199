import throttle from "lodash/throttle";

const mobileNav = document.querySelector(".nav-mobile");
const siteHeader = document.getElementById("site-header");

// Sticky navs
const stickyNav = () => {
  if (document.body.classList.contains("nav-open") || !siteHeader) {
    return false;
  }

  if (window.pageYOffset > 0) {
    if (!siteHeader.classList.contains("scrolled")) {
      siteHeader.classList.add("scrolled");
    }
  } else {
    siteHeader.classList.remove("scrolled");
  }

  return true;
};

const pagePadding = () => {
  const navBar = document.getElementById("site-header");
  if (navBar) {
    const navbarHeight = navBar.offsetHeight;
    const pageContent = document.getElementById("page-content");
    pageContent.style = `padding-top: ${navbarHeight}px`;
  }
};

window.addEventListener("scroll", throttle(stickyNav, 10));
stickyNav();
pagePadding();

// Mobile header
const openButton = document.querySelector("button.open-nav");
const contactsButton = document.querySelector("button.open-contact");
// const infinityNumbers = document.querySelectorAll(".InfinityNumber");
const mobileContact = document.querySelector(".mobile-contact");

const closeOnOutsideClick = e => {
  if (!mobileContact.contains(e.target) && !contactsButton.contains(e.target)) {
    mobileContact.classList.remove("open");
  }
};

const toggleContacts = e => {
  e.preventDefault();
  mobileContact.classList.toggle("open");
  const mobileContactIsOpen = document.querySelector(".mobile-contact.open");
  if (mobileContactIsOpen) {
    window.addEventListener("click", closeOnOutsideClick);
  }
};

const openNav = e => {
  e.preventDefault();
  mobileNav.classList.add("open");
  document.documentElement.className = "nav-open";
  document.body.classList.add("nav-open");
};

if (openButton) {
  openButton.addEventListener("click", openNav);
}

if (contactsButton) {
  contactsButton.addEventListener("click", toggleContacts);
}

// Commented out because we have stopped Infinity for now
// if (infinityNumbers) {
//   infinityNumbers.forEach(number => {
//     number.addEventListener("click", () => {
//       window.location.href = `tel:${number.innerHTML}`;
//     });
//   });
// }

// Mobile nav
const closeButton = document.querySelector(".nav-mobile__close-nav");
const currentPage = document.querySelector(".nav-mobile__current-page");
const secondaryNavLinks = document.querySelectorAll(".secondary-nav__title");
const slideButtons = document.querySelectorAll(".slideBtn");
const slideBackButtons = document.querySelectorAll(".slideBackBtn");

slideButtons.forEach(button => {
  const secondaryNav = document.querySelector(
    `.secondary-nav--${button.dataset.secondaryNav}`
  );
  button.addEventListener("click", () => {
    secondaryNav.classList.add("open");
    secondaryNav.classList.remove("close");
    mobileNav.classList.add("hidden");
    mobileNav.classList.remove("visible");
  });
});

slideBackButtons.forEach(button => {
  button.addEventListener("click", () => {
    button.parentElement.classList.add("close");
    button.parentElement.classList.remove("open");
    mobileNav.classList.remove("hidden");
    mobileNav.classList.add("visible");
  });
});

const closeNav = () => {
  mobileNav.classList.remove("open");
  document.documentElement.className = "";
  document.body.classList.remove("nav-open");
  const secondaryNav = document.querySelector(".secondary-nav.open");
  if (secondaryNav) {
    secondaryNav.classList.remove("open");
    mobileNav.classList.remove("hidden");
  }
};

if (closeButton) {
  closeButton.addEventListener("click", closeNav);
}

if (currentPage) {
  currentPage.addEventListener("click", closeNav);
}

if (secondaryNavLinks) {
  secondaryNavLinks.forEach(link => {
    link.addEventListener("click", closeNav);
  });
}

// Staging nav
if (
  window.SUPERCARERS_API_URL &&
  window.SUPERCARERS_API_URL.startsWith("https://api-staging")
) {
  const logo = document.getElementById("site-logo");
  if (logo) {
    logo.firstElementChild.innerHTML = "💙Staging";
    logo.classList.add("staging");
  }
}

window.addEventListener("load", pagePadding);

window.addEventListener("hashchange", () => {
  const navBar = document.getElementById("site-header");
  if (navBar) {
    const navbarHeight = navBar.offsetHeight;
    scrollBy(0, parseInt(`-${navbarHeight}`, 10));
  }
});
